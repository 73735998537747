<template>
  <div class="vg_wrapper">
    <el-card>
      <publicTips></publicTips>
      <div class="vd_search_group">
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getCpttsNow()">
          <el-row>
            <el-col :md="8">
              <el-form-item label="公司编号:">
                <el-input size="small" v-model.trim="searchForm.cptt_no" clearable placeholder="请填写公司编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="公司中文名称:">
                <el-input size="small" v-model="searchForm.cptt_name" clearable placeholder="请填写公司名称（中文）"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="公司英文名称:">
                <el-input size="small" v-model="searchForm.cptt_name_en" clearable placeholder="请填写公司名称（英文）"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="单据状态:">
                <selectStatus @selectStatusRow="getStatusVal"></selectStatus>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="12">
              <el-form-item label="时间范围:">
                <div class="block">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getCpttsNow()" class="vg_ml_16">查询</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
        <el-button type="danger" size="small" :disabled="!btn.delete" @click="doDelete()"><i class="el-icon-delete"></i> 删除</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            class="vg_cursor"
            ref="multiTable"
            v-loading="loadFlag"
            :data="tableData"
            @selection-change="handleSelectionChange"
            @select="selectRows"
            @select-all="selectRows"
            @row-dblclick="dbClickJp"
            border
          >
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column label="公司编号" prop="cptt_no" />
            <el-table-column label="公司名称（中）" :show-overflow-tooltip="true" prop="cptt_name">
              <template slot-scope="scope">
                <span v-if="scope.row.cptt_name">
                  {{ scope.row.cptt_name }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="公司名称（英）" prop="cptt_name_en" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.cptt_name_en">
                  {{ scope.row.cptt_name_en }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="地址（中）" prop="cptt_address" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.cptt_address">
                  {{ scope.row.cptt_address }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="地址（英）" prop="cptt_address_en" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.cptt_address_en">
                  {{ scope.row.cptt_address_en }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="电话" prop="cptt_phone" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.cptt_phone">
                  {{ scope.row.cptt_phone }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="传真" prop="cptt_fax" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.cptt_fax">
                  {{ scope.row.cptt_fax }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="税号" prop="cptt_tax_no" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.cptt_tax_no">
                  {{ scope.row.cptt_tax_no }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="录入人" prop="stff_name" :show-overflow-tooltip="true" />
            <el-table-column label="录入时间" prop="create_time" :formatter="formatDate" :show-overflow-tooltip="true" />
            <el-table-column label="单据状态" :show-overflow-tooltip="true" align="center">
              <template slot-scope="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">{{
                  helper.getStatusName(scope.row.status).name
                }}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { cpttAPI } from '@api/modules/comptitle';
import selectStatus from '@/views/component/selectStatus';
import pubPagination from '@/components/common/pubPagination';
import publicTips from '@/views/component/publicTips';
export default {
  name: 'CpttList',
  components: {
    selectStatus,
    pubPagination,
    publicTips
  },
  data() {
    return {
      searchForm: {
        cptt_no: '',
        cptt_name: '',
        cptt_name_en: '',
        status: null,
        timeValue: {
          startTime: null,
          endTime: null
        }
      },
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      loadFlag: true
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/cptt_add' || from.path === '/cptt_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getCptts();
    },
    // 获取公司抬头信息
    getCptts() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      get(cpttAPI.getCptts, {
        cptt_no: this.searchForm.cptt_no,
        cptt_name: this.searchForm.cptt_name,
        cptt_name_en: this.searchForm.cptt_name_en,
        status: this.searchForm.status,
        start_time: timeNewVal.startTime,
        end_time: timeNewVal.endTime,
        page_no: this.currentPage
      })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 查询方法
    getCpttsNow() {
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getCptts();
    },
    // 状态查询
    getStatusVal(val) {
      this.searchForm.status = val;
    },
    //查询范围
    getStatusVal2(val) {
      console.log(val);
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getCptts();
    },
    // 多选获取公司抬头信息
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 新增
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/cptt_add?perm_id=${permId}`);
    },
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'warning', message: '已生效的数据不可删除！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    //删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      const ids = [];
      this.multiSelection.map(item => {
        ids.push(item.cptt_id);
      });
      post(cpttAPI.deletCpttByIds, { cptt_id_list: ids })
        .then(res => {
          if (res.data.code === 0) {
            let mg = res.data.msg;
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            if (ids.length === this.tableData.length) {
              if (this.currentPage > 1) {
                this.currentPage = this.currentPage - 1;
                this.$refs.pubPagination.currentPage = this.currentPage;
              }
            }
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
          this.initData();
        });
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/cptt_edit', { perm_id: permId, form_id: row.cptt_id });
    }
  }
};
</script>

<style scoped lang="scss"></style>
